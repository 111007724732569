module.exports={
"label.account.name":``,
"label.account.copy":``,
"label.account.phone.length":``,
"label.account.Start.trial":``,
"label.account.employees1000":``,
"label.account.other.Ways":``,
"label.account.emailsync.button.cancel":``,
"label.account.rule":``,
"label.account.password.update.success":``,
"label.account.describe":``,
"label.account.enter.Emailbox":``,
"label.account.password":``,
"label.account.problem":``,
"label.account.Complete.reset":``,
"label.account.crm.used":``,
"label.account.report.loading":``,
"label.account.notcustomer":``,
"label.account.employees501":``,
"label.account.title.operationManager":``,
"label.account.Preset":``,
"label.account.company":``,
"label.account.come":``,
"label.account.input.password":``,
"label.account.emailobject.emaildetail.button.back":``,
"label.account.title.itManager":``,
"label.account.Modified.successfully":``,
"label.account.only11":``,
"label.account.signin":``,
"label.account.register":``,
"label.account.adopt":``,
"label.account.title.hrManager":``,
"label.account.input.usernames":``,
"label.account.only.Numbers":``,
"label.account.title.salesManager":``,
"label.account.email":``,
"label.account.click":``,
"label.account.and":``,
"label.account.mobile":``,
"label.account.Privacy.policy":``,
"label.account.password.isnull":``,
"label.account.employees101":``,
"label.account.remember.name":``,
"label.account.Select.country":``,
"label.account.distinguish":``,
"label.account.fillMobile":``,
"label.account.Preconfigured":``,
"label.account.Select.job":``,
"label.account.employees21":``,
"label.account.username":``,
"label.account.Submit":``,
"label.account.confirm":``,
"label.account.contact":``,
"label.account.country":``,
"label.account.username.isnull":``,
"label.account.forget.password":``,
"label.account.Loading":``,
"label.account.goon":``,
"label.account.Register.trial":``,
"label.account.title.other":``,
"label.account.surname":``,
"label.account.Fail":``,
"label.account.Terms.service":``,
"label.account.Select.staff":``,
"label.account.username.enter":``,
"label.account.trial":``,
"label.account.employees1":``,
"label.account.Quick.registration":``,
"label.account.tabpage.ok":``,
"label.account.normal.reregister":``,
"label.account.batchadd.newbatchpage.save.em":``,
"label.account..user.title":``,
"label.account.No.credit.card.required":``,
"label.account.enterprise.Wechat":``,
"label.account.sample.data":``,
"label.account.title.hobbies":``,
"label.account.platform":``,
"label.account.sent":``,
"label.account.Confirm.password":``,
"label.account.fill":``,
"label.account.user.resetpw":``,
"label.account.Spell":``,
"label.account.identifying.code":``,
"label.account.staff":``,
"label.account.welcome":``,
"label.account.online.guidance":``,
"label.account.login.CloudCC":``,
"label.account.Company.name":``,
"label.account.title.generalManager":``,
"label.account.enter.user":``,
"label.account.sent.code":``,
"label.account.pw.error.text":``,
"label.account.title.customerManager":``,
"label.account.label.captcha":``,
"label.account.Trial.days":``,
"label.account.please.enter.name":``,
"label.account.inspect":``,
"label.account.User.rule":``,
"label.account.go.Login":``,
"label.account.Reading.consent":``,
"label.account.Change.Password2":``,
"label.account.Loginby":``,
"label.account.apply.partner":``,
"label.account.MobileLogin":``,
"label.account.Registration":``,
"label.account.Italy":``,
"label.account.view.details":``,
"label.account.accept":``,
"label.account.China.Mainland":``,
"label.account.NewPassword":``,
"label.account.Mobile.not.empty":``,
"label.account.Please.select":``,
"label.account.market.introduce":``,
"label.account.IOS":``,
"label.account.failsend":``,
"label.account.UpperLowerlAphanumeric.character":``,
"label.account.New.Password1":``,
"label.account.succSend":``,
"label.account.Account.mobile":``,
"label.account.Login.consent":``,
"label.account.fillAreaCode":``,
"label.account.active.login":``,
"label.account.jp":``,
"label.account.partner.success1":``,
"label.account.Reset":``,
"label.account.no.user.info":``,
"label.account.EnterOldPass":``,
"label.account.Germany":``,
"label.account.title.content":``,
"label.account.Taiwan":``,
"label.account.China.Station":``,
"label.account.sale.introduce":``,
"label.account.Back":``,
"label.account.market":``,
"label.account.section.company":``,
"label.account.check.email":``,
"label.account.characters":``,
"label.account.International":``,
"label.account.britain":``,
"label.account.reg.fail.msg":``,
"label.account.success.reg":``,
"label.account.company.url":``,
"label.account.CannotBeEmpty":``,
"label.account.phone.note":``,
"label.account.second":``,
"label.account.Change.Password":``,
"label.account.Netherlands":``,
"label.account.pleaseAgree":``,
"label.account.copyright":``,
"label.account.letter":``,
"label.account.remember":``,
"label.account.accountNum":``,
"label.account.code.not.empty":``,
"label.account.Spain":``,
"label.account.Alphanumeric":``,
"label.account.obtain":``,
"label.account.company.txt":``,
"label.account.con.browser":``,
"label.account.Android":``,
"label.account.title":``,
"label.account.partner.success2":``,
"label.account.Macao":``,
"label.account.Brazil":``,
"label.account.service.introduce":``,
"label.account.codeFailure":``,
"label.account.AccountLogin":``,
"label.account.pswComplexReqRules":``,
"label.account.UpperLowerlAphanumeric":``,
"label.account.active.username":``,
"label.account.rememberPassword":``,
"label.account.HongKong":``,
"label.account.oldPass":``,
"label.account.Data.storage":``,
"label.account.number":``,
"label.account.Poland":``,
"label.account.service":``,
"label.account.Incorrect.format":``,
"label.account.remember.status":``,
"label.account.sale":``,
"label.account.No.account.binding":``,
"label.account.Russia":``,
"label.account.France":``,
"label.account.partner.content":``,
"label.account.reg.phone.already":``,
"label.account.Confirm":``,
"label.account.VerificationLogin":``,
"label.account.USA":``,
"label.account.mobile.num":``,
"label.account.MinLength":``,
"label.account.change.your.password":``,
"label.account.new.password":``,
"label.account.website.enter":``,
"label.account.pswComplexIsZero":``
}